/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { BottomSpace, ChipSelector, ClassCard, EmptyCard } from 'components';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { LANGUAGE } from 'enum';
import GlobalService from 'services/global.service';
import { ClassType } from 'types/global.type';
import { delayNavigate, isSuccessCode, isVN, resetScrollElement } from 'utils';
import { useDispatch, useSelector } from 'stores';
import { changeSelectedContract, getUserContractsAction, userSelector } from 'stores/user';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import Selection from 'components/input/Selection';
import { isEmpty } from 'lodash';

type Props = {};

export function ClassContainer({}: Props) {
  const { t, i18n } = useTranslation();
  const { goTo } = useDirection();
  const globalService = new GlobalService();
  const dispatch = useDispatch();
  const { userContracts, selectedContract } = useSelector(userSelector);
  const [classes, setClasses] = useState<ClassType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeDate, setActiveDate] = useState('');
  const initNumberOfCard = Math.ceil((window.innerHeight - 64 - 32 - 80 - 80) / 80);

  const getUserContracts = async () => {
    if (isEmpty(userContracts)) {
      await dispatch(getUserContractsAction());
    }
  };

  const getClasses = async () => {
    if (selectedContract?.club?.id && activeDate) {
      setIsLoading(true);
      const response = await globalService.getClasses({
        clubId: Number(selectedContract?.club?.id),
        beginDate: activeDate,
      });
      const { code, data } = response;
      if (isSuccessCode(code)) {
        setClasses(data);
      }
      delayNavigate(() => setIsLoading(false));
    }
  };

  const listAvailableDate = useMemo(() => {
    const result = [];

    for (let i = 0; i < 7; i++) {
      const now = moment();
      const nextDate = now.add(i, 'day');
      if (i18n.language === LANGUAGE.VN) nextDate.locale('vi');
      else nextDate.locale('en-us');
      result.push({ title: nextDate.format('ddd-DD/MM'), value: nextDate.toISOString() });
    }

    setActiveDate(result[0]?.value);

    return result;
  }, []);

  const handleChangeActiveDate = (newDate: string) => {
    resetScrollElement('class-list');
    setActiveDate(newDate);
  };

  const handleChangeContract = (clubId: number | null) => {
    resetScrollElement('class-list');
    const contract = userContracts?.find(
      (contract) => String(contract?.club?.id) === String(clubId),
    );
    contract && dispatch(changeSelectedContract(contract));
  };

  useEffect(() => {
    getUserContracts();
  }, []);

  useEffect(() => {
    getClasses();
  }, [selectedContract, activeDate]);

  return (
    <div className={styles.wrapper}>
      {isEmpty(selectedContract) ? (
        <EmptyCard text={t('paragraph.no-contract')} />
      ) : (
        <>
          <div className={styles.selectClub}>
            <Selection
              options={(userContracts || [])?.map((contract) => ({
                id: Number(contract.club?.id),
                title: String(isVN() ? contract.club?.nameVi : contract.club?.nameEn),
              }))}
              selectedId={Number(selectedContract?.clubId)}
              onChangeCity={handleChangeContract}
              clearable={false}
              iconUrl="/images/icon/building.svg"
            />
          </div>
          <div className={styles.date}>
            <ChipSelector
              activeChipKey={activeDate}
              chipList={listAvailableDate.map((date) => {
                return {
                  key: date.value,
                  title: date.title,
                };
              })}
              onChangeChip={handleChangeActiveDate}
            />
          </div>
          <div className={styles.content}>
            <p className="font-xl font-bold color-blue">{t('title.today-we-have')}</p>
            <div id="class-list" className={styles.listClass}>
              {isLoading ? (
                Array.from(Array(initNumberOfCard).keys()).map((temp) => {
                  return <ClassCard key={temp} isSkeleton />;
                })
              ) : classes.length > 0 ? (
                classes?.map((classItem) => {
                  return (
                    <ClassCard
                      key={classItem.id}
                      beginAt={classItem.classCalendar?.beginningDateTime}
                      duration={classItem.classCalendar?.durationInMins}
                      imageUrl={classItem.imageId}
                      onClick={goTo(ROUTES.CLASS_DETAIL)}
                    />
                  );
                })
              ) : (
                <EmptyCard text={t('paragraph.no-class')} />
              )}
              <BottomSpace />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
