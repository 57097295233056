export enum E_CONTRACT_STATUS {
  PAST = 'PAST',
  CURRENT = 'CURRENT',
  WAIT_FOR_PAYMENT = 'WAITFORPAYMENT',
  FUTURE = 'FUTURE',
}

export enum CHIP_STATUS {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  WAITFORPAYMENT = 'WAITFORPAYMENT',
  WAITPTCONFIRM = 'WAITPTCONFIRM',
  WAITCUSCONFIRM = 'WAITCUSCONFIRM',
  BOOKED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}

export enum VNP_STATUS {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  INFO = 'INFO',
}

export enum E_PAYMENT_TYPE {
  JOINING_FEE = 'JOINING_FEE',
  MEMBERSHIP_FEE = 'MEMBERSHIP_FEE',
}

export enum E_FREEZE_PAYMENT_TYPE {
  FREEZE_CONTRACT = 'FREEZE_CONTRACT',
}

export enum E_PT_CONTRACT_STATUS {
  REGISTER = 'REGISTER',
  WAITPTCONFIRM = 'WAITPTCONFIRM',
  WAITFORPAYMENT = 'WAITFORPAYMENT',
}

export enum E_FREEZE_STATUS {
  WAITFORPAYMENT = 'WAITFORPAYMENT',
  UNFREEZE = 'UNFREEZE',
  CURRENT = 'CURRENT',
}
