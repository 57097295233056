import { render } from 'react-dom';
import App from './App';
import './styles/global.scss';
import './utils/i18n';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'stores';
import { isPWA, saveAppHeight } from 'utils';
import { InstallOverlay } from 'components';

const rootElement = document.getElementById('root');
saveAppHeight();
render(
  <Provider store={store}>
    <BrowserRouter>{isPWA() ? <App /> : <InstallOverlay />}</BrowserRouter>
  </Provider>,
  rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
