import React from 'react';
import styles from './styles.module.scss';
import { Skeleton } from 'antd-mobile';
import { showData } from 'utils';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';

type Props = {
  pin?: string;
  isSkeleton?: boolean;
};

function CheckinEmployeeCard({ pin = '', isSkeleton = false }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <div
        className={styles.qrBlock}
        style={{
          position: 'relative',
          background: 'var(--white-text-color)',
        }}
      >
        <div
          className={styles.wrapperQr}
          style={{
            background: 'var(--black-color)',
          }}
        >
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '12px',
              }}
            />
          ) : (
            <QRCode
              value={String(pin)}
              style={{ height: '100%', width: '100%' }}
              bgColor={'transparent'}
              fgColor={'var(--white-text-color)'}
              viewBox={`0 0 80 80`}
              size={80}
            />
          )}
        </div>
      </div>
      <div className={styles.extendInfo}>
        <div className={styles.info}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-xl)',
                width: '150px',
              }}
            />
          ) : (
            <p className="font-xl font-medium color-white">{showData(pin)}</p>
          )}
        </div>
        <p
          className={styles.membershipName}
          style={{
            color: 'var(--white-text-color)',
          }}
        >
          {t('title.tng-employee')}
        </p>
      </div>
    </div>
  );
}

export default React.memo(CheckinEmployeeCard);
