import SvgIcon from 'components/shared/SVGIcon';
import styles from './styles.module.scss';
import { BottomSpace, SecondaryButton } from 'components';
import { useTranslation } from 'react-i18next';
import { LocalStorage, STORAGE_KEY } from 'utils';

const Storage = new LocalStorage();

export function ReferralContainer() {
  const { t } = useTranslation();

  const handleShare = async () => {
    const phoneNumber = Storage.getStorageItem(STORAGE_KEY.PHONE_NUMBER);

    try {
      await navigator.share({
        text: `${t('paragraph.your-referral-code')}: ${phoneNumber}`,
        title: t('paragraph.referral-invitation'),
      });
    } catch {}
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.backGround}
        style={{
          backgroundImage: 'url(/images/background/bg11.jpeg)',
        }}
      >
        <div className={styles.backgroundOverlay}>
          <p className="font-xl font-bold color-white">{t('title.hurry')}</p>
          <p className="font-xl font-bold color-white">{t('title.deal-end-soon')}</p>
          <p className={styles.hightLightTitle}>{t('title.refer-friend-earn')}</p>
          <p className="font-xl font-bold color-white">{t('title.when-they-join')}</p>
        </div>
        <div className={styles.icon}>
          <SvgIcon
            src="/images/icon/user-plus.svg"
            pathFill="var(--white-text-color)"
            width="45px"
            height="45px"
          />
        </div>
      </div>
      <div className={styles.information}>
        <p className="font-xxl color-black font-bold">{t('title.refer-friend')}</p>
        <p
          className="font-md color-black font-light"
          style={{
            marginTop: '13px',
          }}
        >
          {t('paragraph.share-this-exclusive')}
        </p>
        <p
          className="font-md color-black font-light"
          style={{
            marginTop: '33px',
          }}
        >
          {t('paragraph.every-friend-that-join')}
        </p>
        <p className="font-xxxl color-black font-bold">{t('title.one-week-free')}</p>
      </div>
      <div className={styles.buttonGroup}>
        <SecondaryButton
          text={t('button.share-this-deal')}
          variant="high-light-blue"
          style={{
            maxWidth: '272px',
            width: '100%',
          }}
          onClick={handleShare}
        />
        {/* <SecondaryButton text={t('button.view-my-referrals')} variant="link" /> */}
      </div>
      <BottomSpace />
    </div>
  );
}
