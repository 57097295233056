/* eslint-disable @typescript-eslint/no-unused-vars */
import { Image, Skeleton } from 'antd-mobile';
import styles from './styles.module.scss';
import { SecondaryButton, SvgIcon } from 'components';
import { useTranslation } from 'react-i18next';
import { ClubType } from 'types';
import { useEffect, useState } from 'react';
import { GeneralService } from 'services';
import { useParams } from 'react-router';
import { delayNavigate, generateClubName, isSuccessCode, isVN, joinAddress } from 'utils';
import { ROUTES } from 'constant';
import { useDirection } from 'hooks';

const BASIC_MORE_INFO = [
  {
    icon: '/images/icon/home-outline.svg',
    content: 'paragraph.pr1',
  },
  {
    icon: '/images/icon/whistle.svg',
    content: 'paragraph.pr3',
  },
  {
    icon: '/images/icon/droplet.svg',
    content: 'paragraph.pr4',
  },
  {
    icon: '/images/icon/smartphone.svg',
    content: 'paragraph.pr5',
  },
];

export function ClubDetailContainer() {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const generalService = new GeneralService();
  const [isLoading, setIsLoading] = useState(true);
  const { clubId } = useParams();
  const [clubDetail, setClubDetail] = useState<ClubType>();

  const getClubDetail = async () => {
    setIsLoading(true);
    const response = await generalService.getClubDetail({
      clubId,
    });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setClubDetail(data);
    }

    delayNavigate(() => setIsLoading(false));
  };

  useEffect(() => {
    getClubDetail();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.clubImage}>
        {isLoading ? (
          <Skeleton
            animated
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        ) : (
          <Image alt="club-image" src={clubDetail?.imageUrl} />
        )}
      </div>
      <div className={styles.content}>
        {isLoading ? (
          <Skeleton
            animated
            style={{
              width: '100%',
              height: 'var(--font-xl)',
            }}
          />
        ) : (
          <div className={styles.clubName}>
            <p className="font-xl color-black font-bold">
              {generateClubName(isVN() ? clubDetail?.nameVi : clubDetail?.nameEn)}
            </p>
          </div>
        )}
        <div className={styles.blockInfo}>
          {isLoading ? (
            <Skeleton.Paragraph
              animated
              lineCount={2}
              style={{
                width: '100%',
              }}
            />
          ) : (
            <div className={styles.blockItem}>
              <div className={styles.icon}>
                <SvgIcon src="/images/icon/ping.svg" pathFill="var(--primary-text-color)" />
              </div>
              <div className={styles.blockContent}>
                <p className="font-md color-black font-light">{joinAddress(clubDetail)}</p>
              </div>
            </div>
          )}
          <div className={styles.blockItem}>
            <div className={styles.icon}>
              <SvgIcon src="/images/icon/hot-line.svg" pathFill="var(--primary-text-color)" />
            </div>
            <div className={styles.blockContent}>
              <div className={styles.clubPhone}>
                <p className="font-md color-black font-light">1900 636920</p>
                <p
                  className="font-sm color-blue font-bold"
                  onClick={() => {
                    window.open('tel:1900636920', '_self');
                  }}
                >
                  {t('button.call')}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.blockItem}>
            <div className={styles.icon}>
              <SvgIcon src="/images/icon/clock.svg" pathFill="var(--primary-text-color)" />
            </div>
            <div className={styles.blockContent}>
              <p className="font-md color-black font-light">{t('title.club-open-time')}</p>
              <div className={styles.openDate}>
                <p
                  className="font-sm color-black font-light"
                  style={{
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {t('paragraph.club-open-time')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.whatYouGet}>
        <p className="font-xl color-black font-bold">{t('title.what-you-get')}</p>
        <div className={styles.benefitInfo}>
          {BASIC_MORE_INFO.map((info, idx) => (
            <div key={idx} className={styles.blockItem}>
              <div className={styles.icon}>
                <SvgIcon src={info.icon} pathFill="var(--primary-text-color)" />
              </div>
              <p className="font-md color-black font-light">{t(info.content)}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.controller}>
        <SecondaryButton
          text={t('button.review-plans')}
          variant="high-light-blue"
          onClick={goTo(ROUTES.MEMBERSHIP_SELECT.replace(':clubId', String(clubId)))}
        />
      </div>
    </div>
  );
}
