import { THEME_COLOR } from 'constant';
import CardDetailContainer from 'containers/AccountContainer/PaymentMethodContainer/CardDetailContainer';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function CardDetailPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title={'title.payment-method'}
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      headerBackground="var(--blue-bg-color)"
      isHideFooter
      isHideHome
      isHideBottomSpace
      isWhiteBg
    >
      <CardDetailContainer />
    </SecondaryLayout>
  );
}
