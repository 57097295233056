import { ACCOUNT_TYPE } from 'enum';
import { LocalStorage, STORAGE_KEY } from './localStorage';

const Storage = new LocalStorage();

export const isMember = () => {
  return Storage.getStorageItem(STORAGE_KEY.ACCOUNT_TYPE) === ACCOUNT_TYPE.MEMBER;
};

export const isEmployee = () => {
  return Storage.getStorageItem(STORAGE_KEY.ACCOUNT_TYPE) === ACCOUNT_TYPE.EMPLOYEE;
};
