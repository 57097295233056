/* eslint-disable @typescript-eslint/no-unused-vars */
import { BottomSpace, SvgIcon } from 'components';
import styles from './styles.module.scss';
import NotifyContent from 'components/notify/NotifyContent';
import { useDirection } from 'hooks';
import { useTranslation } from 'react-i18next';
import NotifyService from 'services/notify.service';
import { delayNavigate, isSuccessCode, showData } from 'utils';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { NotifyType } from 'types/notify.type';
import { NOTIFY_CONTENT } from 'constant/notify';
import { Skeleton } from 'antd-mobile';

export function NotificationDetailContainer() {
  const { notifyId } = useParams();
  const { t } = useTranslation();
  const { goBack } = useDirection();
  const notifyService = new NotifyService();
  const [notifyDetail, setNotifyDetail] = useState<NotifyType>();
  const [isLoading, setIsLoading] = useState(false);

  const getNotifyDetail = async () => {
    setIsLoading(true);
    const response = await notifyService.getNotificationDetail({ notificationId: notifyId });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setNotifyDetail(data);
    }

    delayNavigate(() => setIsLoading(false));
  };

  const handleReadNotify = () => {
    notifyService.readNotify({ notificationId: notifyId });
  };

  useEffect(() => {
    getNotifyDetail();
    handleReadNotify();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.title}>
            {isLoading ? (
              <Skeleton
                animated
                style={{
                  width: '200px',
                  height: 'var(--font-xl)',
                }}
              />
            ) : (
              <p className="font-xl font-bold color-blue">
                {t(showData(notifyDetail && NOTIFY_CONTENT[notifyDetail?.template?.action]?.title))}
              </p>
            )}
          </div>
          <div className={styles.backIcon} onClick={goBack}>
            <SvgIcon
              src="/images/icon/left-arrow.svg"
              pathFill="var(--primary-color)"
              style={{
                width: '15px',
                height: '23px',
              }}
            />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.backgroundIcon}>
            <SvgIcon
              src={notifyDetail ? NOTIFY_CONTENT[notifyDetail?.template?.action]?.icon : ''}
              width="158px"
              height="158px"
            />
          </div>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                width: '200px',
                height: '32px',
              }}
            />
          ) : (
            <p className={styles.title}>
              {t(showData(notifyDetail && NOTIFY_CONTENT[notifyDetail?.template?.action]?.content))}
            </p>
          )}
          <div className={styles.notifyContent}>
            {isLoading ? (
              <Skeleton.Paragraph
                animated
                lineCount={10}
                style={{
                  width: '100%',
                }}
              />
            ) : (
              <NotifyContent data={notifyDetail?.param} action={notifyDetail?.template?.action} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
