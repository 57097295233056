import { GuidelineCard } from 'components';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { GeneralService } from 'services';
import { delayNavigate, isSuccessCode, isVN } from 'utils';
import { useEffect, useRef, useState } from 'react';
import { WorkoutTutorialType } from 'types/general.type';
import { useDisclosure } from 'hooks';
import YoutubeModal from 'components/modal/YoutubeModal';
import LoadingDot from 'components/shared/LoadingDot';

export function WorkoutWithUsContainer() {
  const generalService = new GeneralService();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [listWorkout, setListWorkout] = useState<WorkoutTutorialType[]>([]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const selectedVideo = useRef<WorkoutTutorialType | undefined>();

  const getListWorkout = async () => {
    const response = await generalService.getWorkoutTutorial();
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setListWorkout(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  const openModal = (tutorial: WorkoutTutorialType) => () => {
    onOpen();
    selectedVideo.current = tutorial;
  };

  useEffect(() => {
    getListWorkout();
  }, []);

  return (
    <div className={styles.container}>
      <p className="font-black font-xl font-bold">{t('title.let-get-started')}</p>
      <div className={styles.classes}>
        {isLoading ? (
          <LoadingDot />
        ) : (
          listWorkout?.map((workout) => {
            return (
              <div key={workout.id} className={styles.class}>
                <GuidelineCard
                  name={isVN() ? workout.nameVi : workout.nameEn}
                  thumbnail={workout.thumbnail}
                  onClick={openModal(workout)}
                />
              </div>
            );
          })
        )}
      </div>
      <YoutubeModal
        isOpen={isOpen}
        onClose={onClose}
        embeddedLink={selectedVideo.current?.youtubeLink}
      />
    </div>
  );
}
