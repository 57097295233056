import { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { BottomSpace, PaymentMethodSelector, SecondaryButton } from 'components';
import { useLocation } from 'react-router';
import { delayNavigate, formatPrice, isSuccessCode, isVN, showData } from 'utils';
import { DATE_FORMAT, ROUTES } from 'constant';
import { CreateFreezeContractReq, VN_PAY_RESPONSE } from 'types';
import { dispatch } from 'stores';
import { useDirection, useDisclosure } from 'hooks';
import { useTranslation } from 'react-i18next';
import { CARD_TYPE, E_PAYMENT_METHOD, PAYMENT_GROUP } from 'enum';
import PaymentService from 'services/payment.service';
import { SavedCardType } from 'types/payment.type';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { generalSelector, getFreezeDurationAction, getFreezePriceAction } from 'stores/general';
import moment from 'moment';
import { Skeleton } from 'antd-mobile';
import ConfirmModal from 'components/modal/ConfirmModal';
import FreezeService from 'services/freeze.service';
import { GeneralService } from 'services';

export function PaymentFreezeContainer() {
  const { goTo } = useDirection();
  const paymentService = new PaymentService();
  const freezeService = new FreezeService();
  const generalService = new GeneralService();
  const { t } = useTranslation();
  const { goBack } = useDirection();
  const { freezeDurationConfig } = useSelector(generalSelector);
  const [selectedMethodId, setSelectedMethodId] = useState(E_PAYMENT_METHOD.QR);
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const [listSaveCard, setListSavedCard] = useState<SavedCardType[]>([]);
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);
  const [freezePrice, setFreezePrice] = useState(0);
  const { search } = useLocation();

  const initCallApi = async () => {
    setIsLoading(true);
    await getCurrentFreezePrice();
    await dispatch(getFreezePriceAction());
    await dispatch(getFreezeDurationAction());
    delayNavigate(() => setIsLoading(false));
  };

  const getCurrentFreezePrice = async () => {
    setIsLoadingPrice(true);
    const response = await generalService.getFreezePrice();
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setFreezePrice(data?.settingValueEn?.value || 0);
    }
    setIsLoadingPrice(false);
  };

  const transactionStatus = useMemo(() => {
    const data = queryString.parse(search) as VN_PAY_RESPONSE;

    return data;
  }, [search]);

  const calculateStartEnd = useMemo(() => {
    const current = moment();
    const endDate = moment().add(
      freezeDurationConfig?.settingValueEn?.value,
      freezeDurationConfig?.settingValueEn?.unit,
    );

    return {
      start: current.format(DATE_FORMAT),
      end: endDate.format(DATE_FORMAT),
    };
  }, [freezeDurationConfig]);

  const getExistCard = async () => {
    const response = await paymentService.getExistCards();
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setListSavedCard(data);
    }
  };

  const handleChangeMethodId = (methodId: number) => {
    setSelectedMethodId(methodId);
  };

  const handleChangeCardId = (cardId: number | null) => {
    setSelectedCardId(cardId);
  };

  const handleAddCard = async () => {
    const currentURl = [window.location.origin, window.location.pathname].join('');

    const response = await paymentService.addVNPayAddCardUrl({
      locale: isVN() ? 'vn' : 'en',
      cancelUrl: `${currentURl}?isVNPayResponse=0`,
      cardType: CARD_TYPE.INTERNATIONAL,
      returnUrl: `${currentURl}?isVNPayResponse=1`,
    });
    const { data, code } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.vnpayUrl;
    }
  };

  const paymentByCard = async (freezeContractId: number) => {
    const currentURl = [window.location.origin, window.location.pathname].join('');
    const response = await paymentService.payFreezeWithVNPayCard({
      freezeContractId: String(freezeContractId),
      locale: isVN() ? 'vn' : 'en',
      cancelUrl: currentURl,
      returnUrl: [
        window.location.origin,
        ROUTES.MEMBERSHIP_FREEZE_PAYMENT_RESULT.replace(
          ':freezeContractId',
          String(freezeContractId),
        ),
      ].join(''),
      vnpayTokenId: String(selectedCardId),
    });

    const { code, data } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.vnpayPaymentUrl;
    }
  };

  const paymentByQR = async (freezeContractId: number) => {
    const currentURl = [window.location.origin, window.location.pathname].join('');
    const response = await paymentService.payFreezeWithPayOS({
      freezeContractId: freezeContractId,
      cancelUrl: currentURl,
      returnUrl: [
        window.location.origin,
        ROUTES.MEMBERSHIP_FREEZE_PAYMENT_RESULT.replace(
          ':freezeContractId',
          String(freezeContractId),
        ),
      ].join(''),
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.checkoutUrl;
    }
  };

  const createFreezePayment = async () => {
    const reqData: CreateFreezeContractReq = {
      paymentMethodGroupId: PAYMENT_GROUP.PAY_IN_COUNTER,
    };
    const response = await freezeService.createFreezeContract(reqData);
    const { code, data } = response;
    if (isSuccessCode(code)) {
      if (freezePrice === 0) {
        delayNavigate(() => {
          goTo(
            ROUTES.MEMBERSHIP_FREEZE_PAYMENT_RESULT.replace(
              ':freezeContractId',
              String(data?.freezeContract?.id),
            ),
          )();
        });
      } else {
        if (selectedMethodId === E_PAYMENT_METHOD.CASH) {
          delayNavigate(() => {
            goTo(
              ROUTES.MEMBERSHIP_FREEZE_PAYMENT_RESULT.replace(
                ':freezeContractId',
                String(data?.freezeContract?.id),
              ),
            )();
          });
        } else if (selectedMethodId === E_PAYMENT_METHOD.CARD) {
          await paymentByCard(data?.freezeContract?.id);
        } else if (selectedMethodId === E_PAYMENT_METHOD.QR) {
          await paymentByQR(data?.freezeContract?.id);
        }
      }
    }
  };

  useEffect(() => {
    initCallApi();
  }, []);

  useEffect(() => {
    if (transactionStatus.isVNPayResponse === '1') {
      setTimeout(async () => {
        await getExistCard();
        setSelectedMethodId(E_PAYMENT_METHOD.CARD);
      }, 500);
    } else {
      getExistCard();
    }
  }, [transactionStatus]);

  return (
    <div className={styles.wrapper}>
      <div
        className="container"
        style={{
          marginTop: '20px',
        }}
      >
        <p className={`color-dark-black font-bold font-lg ${styles.title}`}>
          {t('title.payment-summary')}
        </p>
      </div>

      <div className={styles.paymentInfo}>
        <div className={styles.blockInfo}>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <>
              <p className="font-md font-light color-black">{t('title.freeze-start-date')}</p>
              <p className="font-md font-medium color-dark-black">{calculateStartEnd.start}</p>
            </>
          )}
        </div>
        <div className={styles.blockInfo}>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <>
              <p className="font-md font-light color-black">{t('title.freeze-end-date')}</p>
              <p className="font-md font-medium color-dark-black">{calculateStartEnd.end}</p>
            </>
          )}
        </div>
        <div className={styles.blockInfo}>
          {isLoading || isLoadingPrice ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-xxl)',
              }}
            />
          ) : (
            <>
              <p
                className="font-md font-light color-black"
                style={{
                  textTransform: 'uppercase',
                }}
              >
                {t('title.total')}
              </p>
              <p className="font-xxl font-bold color-dark-black">
                {freezePrice ? showData(formatPrice(freezePrice)) : t('title.free')}
              </p>
            </>
          )}
        </div>
      </div>

      <div className="container">
        <p
          className={`font-sm font-light ${styles.title}`}
          style={{
            margin: '24px 0',
          }}
        >
          {t('paragraph.pr37')}
          <span className="color-orange"> {t('paragraph.pr38')}</span>
        </p>
        <p className={`color-dark-black font-bold font-lg ${styles.title}`}>
          {t('title.select-payment-method')}
        </p>
        <div className={styles.paymentSelector}>
          <PaymentMethodSelector
            listCard={listSaveCard}
            selectedMethodId={selectedMethodId}
            selectedCardId={selectedCardId}
            onAddNewCard={handleAddCard}
            onChangePaymentMethod={handleChangeMethodId}
            onChangeCard={handleChangeCardId}
          />
        </div>
      </div>
      <div className={styles.controller}>
        <SecondaryButton text={t('button.back')} variant="outline" onClick={goBack} />
        <SecondaryButton
          text={t('button.confirm')}
          variant="high-light-blue"
          onClick={onOpenConfirm}
        />
      </div>
      <ConfirmModal
        confirmTitle={t('title.confirm')}
        confirmContent={t('paragraph.sure-payment')}
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        onConfirm={createFreezePayment}
      />
      <BottomSpace />
    </div>
  );
}
