import { THEME_COLOR } from 'constant';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function ContactPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title={'title.call-us'}
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      headerBackground="var(--blue-bg-color)"
      isHideFooter
      isHideHome
      isHideBottomSpace
      isWhiteBg
    >
      <div
        style={{
          width: '100%',
          height: 'calc(var(--app-height) - var(--header-height) - var(--header-slogan-height))',
        }}
      >
        <iframe width="100%" height="100%" src="https://thenewgym.vn/en/contact-us/" />
      </div>
    </SecondaryLayout>
  );
}
