import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { BottomSpace, EmptyCard, TransactionHistoryCard } from 'components';
import { PTContractType } from 'types';
import { UserService } from 'services';
import { delayNavigate, formatDate, formatTime, isSuccessCode, isVN } from 'utils';
import { isEmpty } from 'lodash';
import { useDirection } from 'hooks';
import { ROUTES } from 'constant';
import { useTranslation } from 'react-i18next';
import LoadingDot from 'components/shared/LoadingDot';

type Props = {};

export default function PTTab({}: Props) {
  const { t } = useTranslation();
  const userService = new UserService();
  const [contracts, setContracts] = useState<PTContractType[]>([]);
  const { goTo } = useDirection();
  const [isLoading, setIsLoading] = useState(false);

  const getListContract = async () => {
    setIsLoading(true);
    const response = await userService.getPTContracts({});
    const { data, code } = response;
    if (isSuccessCode(code)) {
      setContracts(data?.data);
    }
    delayNavigate(() => {
      setIsLoading(false);
    });
  };

  const parseInfo = (contract: PTContractType) => {
    return {
      id: contract?.id,
      isSuccess: !isEmpty(contract?.paymentCompletionDate),
      totalPayment: Number(contract?.paymentTotal),
      planName: isVN() ? contract?.ptPackage?.nameVi : contract?.ptPackage?.nameEn,
      paymentDate: contract.paymentCompletionDate,
    };
  };

  useEffect(() => {
    getListContract();
  }, []);

  return (
    <div className={styles.tabWrapper}>
      <div className={styles.listContract}>
        {isLoading ? (
          <LoadingDot />
        ) : contracts?.length === 0 ? (
          <EmptyCard text={t('paragraph.no-contract-found')} />
        ) : (
          contracts.map((contract) => {
            const info = parseInfo(contract);

            return (
              <TransactionHistoryCard
                key={info?.id}
                isSuccess={info?.isSuccess}
                price={info?.totalPayment}
                name={info?.planName}
                createdDate={info?.paymentDate && formatDate(info?.paymentDate)}
                createdTime={info?.paymentDate && formatTime(info?.paymentDate)}
                onClick={goTo(ROUTES.HISTORY_PT_DETAIL.replace(':ptContractId', String(info?.id)))}
              />
            );
          })
        )}

        <BottomSpace />
      </div>
    </div>
  );
}
