import { Loading } from 'antd-mobile';
import React from 'react';

type Props = {};

export default function LoadingDot({}: Props) {
  return (
    <Loading
      style={{
        fontSize: '24px',
        display: 'block',
        width: '100%',
        textAlign: 'center',
        color: 'var(--primary-color)',
      }}
    />
  );
}
