import { useEffect, useRef, useState } from 'react';
import { UserService } from 'services';
import { BookingHistoryType, ClubType, ContractType, FreeSessionInfoType } from 'types';
import { delayNavigate, isSuccessCode, isVN } from 'utils';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Selection from 'components/input/Selection';
import { E_CONTRACT_STATUS } from 'enum';
import { BottomSpace, CustomTitle, EmptyCard, PTBookingCard, TimePicker } from 'components';
import moment from 'moment';
import { useDisclosure } from 'hooks';
import ConfirmModal from 'components/modal/ConfirmModal';
import HistoryFreeBookingCard from 'components/card/HistoryFreeBookingCard';
import LoadingDot from 'components/shared/LoadingDot';

type Props = {};

export default function ExperienceTab({}: Props) {
  const { t } = useTranslation();
  const userService = new UserService();
  const [listClub, setListClub] = useState<ClubType[]>([]);
  const [selectedClub, setSelectedClub] = useState<number | null>(null);
  const [freezeSessionInfo, setFreezeSessionInfo] = useState<FreeSessionInfoType>();
  const [bookingHistory, setBookingHistory] = useState<BookingHistoryType[]>([]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [rangeTime, setRangeTime] = useState({ from: '', to: '' });
  const [time, setTime] = useState({
    hour: '00',
    minute: '00',
  });
  const [loading, setLoading] = useState({
    cardLoading: false,
    contractLoading: false,
    bookingLoading: false,
    historyLoading: false,
  });
  const { isOpen: isOpenAccept, onOpen: onOpenAccept, onClose: onCloseAccept } = useDisclosure();
  const { isOpen: isOpenReject, onOpen: onOpenReject, onClose: onCloseReject } = useDisclosure();
  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure();
  const bookingIdRef = useRef('');

  const getListBookingHistory = async () => {
    if (selectedClub) {
      setLoading((prev) => ({ ...prev, historyLoading: true }));
      const response = await userService.getListBookingExperienceHistory(String(selectedClub));
      const { code, data } = response;
      if (isSuccessCode(code)) {
        setBookingHistory(data);
      }
      setLoading((prev) => ({ ...prev, historyLoading: false }));
    }
  };

  const getListMembershipContracts = async () => {
    setLoading((prev) => ({ ...prev, contractLoading: true }));
    const response = await userService.getContracts({
      status: E_CONTRACT_STATUS.CURRENT,
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setListClub((data?.data || [])?.map((contract: ContractType) => contract?.club));
    }
    delayNavigate(() => setLoading((prev) => ({ ...prev, contractLoading: false })));
  };

  const getFreeSessionInfo = async () => {
    if (selectedClub) {
      setLoading((prev) => ({ ...prev, cardLoading: true }));

      const response = await userService.getFreezeSessionInfo(String(selectedClub));
      const { code, data } = response;
      if (isSuccessCode(code)) {
        setFreezeSessionInfo(data);
      }
      delayNavigate(() => setLoading((prev) => ({ ...prev, cardLoading: false })));
    }
  };

  const handleChangeClub = (clubId: number | null) => {
    setSelectedClub(clubId);
  };

  const handleChangeTime = (time: { hour: string; minute: string }) => {
    setTime(time);
  };

  const openCancelConfirm = (id: string) => () => {
    bookingIdRef.current = id;
    onOpenCancel();
  };

  const openRejectConfirm = (id: string) => () => {
    bookingIdRef.current = id;
    onOpenReject();
  };

  const openAcceptConfirm = (id: string) => () => {
    bookingIdRef.current = id;
    onOpenAccept();
  };

  const openTimePicker = () => {
    const from = moment().add('minute', 5);
    const to = moment().add('minute', 14);
    setRangeTime({
      from: from.format('HH:mm'),
      to: to.format('HH:mm'),
    });
    setTime({
      hour: from.get('hour').toString().padStart(2, '0'),
      minute: from.get('minute').toString().padStart(2, '0'),
    });
    onOpen();
  };

  const handleBooking = async () => {
    setLoading((prev) => ({ ...prev, bookingLoading: true }));
    const startDate = moment()
      .set('hour', Number(time.hour))
      .set('minute', Number(time.minute))
      .toISOString();
    const response = await userService.bookFreezeSession({
      freeSessionId: String(freezeSessionInfo?.memberFreeSessions[0]?.id),
      startDate: startDate,
    });
    const { code } = response;
    if (isSuccessCode(code)) {
      await getListBookingHistory();
      getFreeSessionInfo();
      onClose();
    }
    delayNavigate(() => setLoading((prev) => ({ ...prev, bookingLoading: false })));
  };

  const handleCancelBooking = async () => {
    const response = await userService.cancelBookingFreeSession(bookingIdRef.current);
    const { code } = response;
    if (isSuccessCode(code)) {
      await getListBookingHistory();
      getFreeSessionInfo();
    }
  };

  const handleRejectBooking = async () => {
    const response = await userService.rejectBookingFreeSession(bookingIdRef.current);
    const { code } = response;
    if (isSuccessCode(code)) {
      await getListBookingHistory();
      getFreeSessionInfo();
    }
  };

  const handleAcceptBooking = async () => {
    const response = await userService.acceptBookingFreeSession(bookingIdRef.current);
    const { code } = response;
    if (isSuccessCode(code)) {
      await getListBookingHistory();
      getFreeSessionInfo();
    }
  };

  useEffect(() => {
    if (listClub.length > 0) {
      setSelectedClub(Number(listClub[0]?.id));
    }
  }, [listClub]);

  useEffect(() => {
    getFreeSessionInfo();
    getListBookingHistory();
  }, [selectedClub]);

  useEffect(() => {
    getListMembershipContracts();
  }, []);

  return (
    <div className={styles.container}>
      {!loading.contractLoading && listClub.length === 0 ? (
        <EmptyCard text={t('paragraph.no-experience-session')} />
      ) : (
        <>
          <div className={styles.controller}>
            <Selection
              isSkeleton={loading.contractLoading}
              options={(listClub || [])?.map((club) => ({
                id: Number(club?.id),
                title: String(isVN() ? club?.nameVi : club?.nameEn),
              }))}
              selectedId={Number(selectedClub)}
              onChangeCity={handleChangeClub}
              clearable={false}
              iconUrl="/images/icon/building.svg"
            />
          </div>
          <div className={styles.body}>
            <div className={styles.bookingContent}>
              <PTBookingCard
                isSkeleton={loading.cardLoading}
                imageUrl={freezeSessionInfo?.ptInfo?.accountDetail?.avatar}
                name={[
                  freezeSessionInfo?.ptInfo?.accountDetail?.firstName,
                  freezeSessionInfo?.ptInfo?.accountDetail?.lastName,
                ].join(' ')}
                numberSessionLeft={freezeSessionInfo?.totalFreeSessions}
                totalSession={freezeSessionInfo?.totalFreeSessions}
                onBooking={openTimePicker}
              />
            </div>

            <div className={styles.history}>
              <CustomTitle text={t('title.booking-history')} />
              {loading.historyLoading ? (
                <LoadingDot />
              ) : bookingHistory.length > 0 ? (
                bookingHistory?.map((history) => {
                  return (
                    <HistoryFreeBookingCard
                      key={history.id}
                      startTime={history.startDate}
                      endTime={history.endDate}
                      date={history.startDate}
                      status={history.status}
                      isExperience
                      onCancel={openCancelConfirm(String(history.id))}
                      onAccept={openAcceptConfirm(String(history.id))}
                      onReject={openRejectConfirm(String(history.id))}
                    />
                  );
                })
              ) : (
                <EmptyCard text={t('paragraph.no-booking-yet')} />
              )}
            </div>
            <BottomSpace />
          </div>

          <TimePicker
            value={time}
            isOpen={isOpen}
            onClose={onClose}
            onChange={handleChangeTime}
            onConfirm={handleBooking}
            isLoading={loading.bookingLoading}
            note={t('title.booking-time-from-to', { from: rangeTime.from, to: rangeTime.to })}
          />

          <ConfirmModal
            isOpen={isOpenAccept}
            onClose={onCloseAccept}
            onConfirm={handleAcceptBooking}
            confirmTitle={t('title.confirm')}
            confirmContent={t('paragraph.accept-booking-confirm')}
          />

          <ConfirmModal
            isOpen={isOpenReject}
            onClose={onCloseReject}
            onConfirm={handleRejectBooking}
            confirmTitle={t('title.confirm')}
            confirmContent={t('paragraph.reject-booking-confirm')}
          />

          <ConfirmModal
            isOpen={isOpenCancel}
            onClose={onCloseCancel}
            onConfirm={handleCancelBooking}
            confirmTitle={t('title.confirm')}
            confirmContent={t('paragraph.cancel-booking-confirm')}
          />
        </>
      )}
    </div>
  );
}
