import { THEME_COLOR } from 'constant';
import { ChoosePTContainer } from 'containers';
import usePWATemplateColor from 'hooks/usePWATemplateColor';
import { SecondaryLayout } from 'layouts';

type Props = {};

export default function ChoosePTPage({}: Props) {
  usePWATemplateColor(THEME_COLOR.WHITE);

  return (
    <SecondaryLayout
      title={'title.personal-trainer'}
      headerBackground="var(--blue-bg-color)"
      titleColor="var(--primary-color)"
      arrowColor="var(--primary-color)"
      isHideFooter
      isHideHome
      isWhiteBg
      isHideBottomSpace
    >
      <ChoosePTContainer />
    </SecondaryLayout>
  );
}
