import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useParams } from 'react-router';
import { delayNavigate, formatDate, formatDateTime, isSuccessCode, isVN } from 'utils';
import { useTranslation } from 'react-i18next';
import { useDirection } from 'hooks';
import PTService from 'services/pt.service';
import { PTContractType } from 'types';
import { PTContractDetailCard, SecondaryButton } from 'components';
import { ROUTES } from 'constant';

type Props = { isHistoryPage?: boolean };

export default function PTPaymentResultContainer({ isHistoryPage = false }: Props) {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const ptService = new PTService();
  const { ptContractId } = useParams();
  const [contract, setContract] = useState<PTContractType>();
  const [isLoading, setIsLoading] = useState(false);

  const getContractDetail = async () => {
    setIsLoading(true);
    const response = await ptService.getPTContractDetail({ ptContractId: String(ptContractId) });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setContract(data);
    }

    delayNavigate(() => setIsLoading(false));
  };

  useEffect(() => {
    getContractDetail();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.transactionCard}>
        <PTContractDetailCard
          isHideExpand={isHistoryPage}
          isSkeleton={isLoading}
          status={contract?.status}
          clubName={isVN() ? contract?.club?.nameVi : contract?.club?.nameEn}
          ptPackageName={isVN() ? contract?.ptPackage?.nameVi : contract?.ptPackage?.nameEn}
          paymentDate={
            contract?.paymentCompletionDate && formatDateTime(contract?.paymentCompletionDate)
          }
          transactionId={contract?.orderRef}
          ptPackagePrice={Number(contract?.ptPackage?.price)}
          startDate={formatDate(contract?.startDate)}
          endDate={formatDate(contract?.endDate)}
          totalPayment={Number(contract?.paymentTotal)}
        />
      </div>
      {!isHistoryPage && (
        <div className={styles.controller}>
          <SecondaryButton
            text={t('button.go-to-home')}
            variant="high-light-blue"
            style={{
              width: '100%',
            }}
            onClick={goTo(ROUTES.HOME)}
          />
        </div>
      )}
    </div>
  );
}
