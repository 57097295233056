import { Mask } from 'antd-mobile';
import React from 'react';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  embeddedLink?: string;
};

function YoutubeModal({ isOpen = false, onClose, embeddedLink }: Props) {
  return (
    <Mask
      visible={isOpen}
      onMaskClick={onClose}
      destroyOnClose
      disableBodyScroll
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 159, 194, 0.20)',
        zIndex: 1000,
      }}
    >
      <iframe
        width={window.innerWidth}
        height={window.innerWidth * (9 / 16)}
        src={`${embeddedLink}?autoplay=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </Mask>
  );
}

export default React.memo(YoutubeModal);
