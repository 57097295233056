/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './styles.module.scss';
import { Skeleton } from 'antd-mobile';
import { BottomSpace, PaymentMethodSelector, SecondaryButton } from 'components';
import ConfirmModal from 'components/modal/ConfirmModal';
import { ROUTES } from 'constant';
import { CARD_TYPE, E_PAYMENT_METHOD, PAYMENT_GROUP } from 'enum';
import { useDirection, useDisclosure } from 'hooks';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { UserService } from 'services';
import PaymentService from 'services/payment.service';
import { CreatePTContractReq, TransactionPTType, VN_PAY_RESPONSE } from 'types';
import { SavedCardType } from 'types/payment.type';
import { delayNavigate, formatPrice, generateClubName, isSuccessCode, isVN } from 'utils';

type Props = {};

export function PTPaymentContainer({}: Props) {
  const { goTo } = useDirection();
  const userService = new UserService();
  const paymentService = new PaymentService();
  const { t } = useTranslation();
  const { goBack } = useDirection();
  const [selectedMethodId, setSelectedMethodId] = useState(E_PAYMENT_METHOD.QR);
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const [listSaveCard, setListSavedCard] = useState<SavedCardType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState<TransactionPTType>();
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure();
  const { search } = useLocation();
  const { contractId } = useParams();

  const getPTContractDetail = async () => {
    setIsLoading(true);
    const response = await userService.getRegisteredPTContract({
      ptContractId: String(contractId),
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      setTransactionDetail(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  const initCallApi = async () => {
    setIsLoading(true);
    getPTContractDetail();
    delayNavigate(() => setIsLoading(false));
  };

  const transactionStatus = useMemo(() => {
    const data = queryString.parse(search) as VN_PAY_RESPONSE;

    return data;
  }, [search]);

  const getExistCard = async () => {
    const response = await paymentService.getExistCards();
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setListSavedCard(data);
    }
  };

  const totalAmountPayBefore = useMemo(() => {
    return Number(transactionDetail?.paymentTotal) - Number(transactionDetail?.transactionAmount);
  }, [transactionDetail]);

  const handleChangeMethodId = (methodId: number) => {
    setSelectedMethodId(methodId);
  };

  const handleChangeCardId = (cardId: number | null) => {
    setSelectedCardId(cardId);
  };

  const handleAddCard = async () => {
    const currentURl = [window.location.origin, window.location.pathname].join('');

    const response = await paymentService.addVNPayAddCardUrl({
      locale: isVN() ? 'vn' : 'en',
      cancelUrl: `${currentURl}?isVNPayResponse=0`,
      cardType: CARD_TYPE.INTERNATIONAL,
      returnUrl: `${currentURl}?isVNPayResponse=1`,
    });
    const { data, code } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.vnpayUrl;
    }
  };

  const paymentByQR = async (ptContractId: string) => {
    const currentURl = [window.location.origin, window.location.pathname].join('');
    const response = await paymentService.payPtWithPayOS({
      ptContractId: ptContractId,
      cancelUrl: currentURl,
      returnUrl: [
        window.location.origin,
        ROUTES.PT_PAYMENT_RESULT.replace(':ptContractId', String(contractId)),
      ].join(''),
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.checkoutUrl;
    }
  };

  const paymentByCard = async (ptContractId: string) => {
    const currentURl = [window.location.origin, window.location.pathname].join('');
    const response = await paymentService.payPtWithVNPayCard({
      ptContractId: ptContractId,
      locale: isVN() ? 'vn' : 'en',
      cancelUrl: currentURl,
      returnUrl: [
        window.location.origin,
        ROUTES.PT_PAYMENT_RESULT.replace(':ptContractId', String(contractId)),
      ].join(''),
      vnpayTokenId: String(selectedCardId),
    });
    const { code, data } = response;
    if (isSuccessCode(code)) {
      window.location.href = data?.vnpayPaymentUrl;
    }
  };

  const createPTContract = async () => {
    const reqData: CreatePTContractReq = {
      paymentMethodGroupId: PAYMENT_GROUP.PAY_IN_COUNTER,
      ptContractId: String(contractId),
    };

    const response = await userService.createPTContract(reqData);
    const { code, data } = response;
    if (isSuccessCode(code)) {
      const contractId = data?.ptContractId;
      if (selectedMethodId === E_PAYMENT_METHOD.CASH) {
        delayNavigate(() => {
          goTo(ROUTES.PT_PAYMENT_RESULT.replace(':ptContractId', String(contractId)))();
        });
      } else if (selectedMethodId === E_PAYMENT_METHOD.CARD) {
        await paymentByCard(contractId);
      } else if (selectedMethodId === E_PAYMENT_METHOD.QR) {
        await paymentByQR(contractId);
      }
    }
  };

  useEffect(() => {
    initCallApi();
  }, []);

  useEffect(() => {
    if (transactionStatus.isVNPayResponse === '1') {
      setTimeout(async () => {
        await getExistCard();
        setSelectedMethodId(E_PAYMENT_METHOD.CARD);
      }, 500);
    } else {
      getExistCard();
    }
  }, [transactionStatus]);

  return (
    <div className={styles.wrapper}>
      <div
        className="container"
        style={{
          marginTop: '20px',
        }}
      >
        <p className={`color-dark-black font-bold font-lg ${styles.title}`}>
          {t('title.payment-summary')}
        </p>
      </div>

      <div className={styles.paymentInfo}>
        <div className={styles.blockInfo}>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <>
              <p className="font-md font-light color-black">{t('title.club')}</p>
              <p className="font-md font-medium color-dark-black">
                {generateClubName(
                  isVN()
                    ? transactionDetail?.ptContract.club?.nameVi
                    : transactionDetail?.ptContract.club?.nameEn,
                )}
              </p>
            </>
          )}
        </div>
        <div className={styles.blockInfo}>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <>
              <p className="font-md font-light color-black">PT</p>
              <p className="font-md font-medium color-dark-black">
                {isVN()
                  ? transactionDetail?.ptContract.ptPackage?.nameVi
                  : transactionDetail?.ptContract.ptPackage?.nameEn}
              </p>
            </>
          )}
        </div>
        <div className={styles.blockInfo}>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-md)',
              }}
            />
          ) : (
            <>
              <p className="font-md font-light color-black">
                {' '}
                {isVN()
                  ? transactionDetail?.ptContract.ptPackage?.nameVi
                  : transactionDetail?.ptContract.ptPackage?.nameEn}
              </p>
              <p className="font-md font-medium color-dark-black">
                {formatPrice(Number(transactionDetail?.paymentTotal))}
              </p>
            </>
          )}
        </div>
        {(totalAmountPayBefore > 0 || isLoading) && (
          <div className={styles.blockInfo}>
            {isLoading ? (
              <Skeleton
                animated
                style={{
                  height: 'var(--font-md)',
                }}
              />
            ) : (
              <>
                <p className="font-md font-light color-black">{t('title.paid-amount')}</p>
                <p className="font-md font-medium color-orange">
                  - {formatPrice(totalAmountPayBefore)}
                </p>
              </>
            )}
          </div>
        )}
        <div className={styles.blockInfo}>
          {isLoading ? (
            <Skeleton
              animated
              style={{
                height: 'var(--font-xxl)',
              }}
            />
          ) : (
            <>
              <p
                className="font-md font-light color-black"
                style={{
                  textTransform: 'uppercase',
                }}
              >
                {t('title.total')}
              </p>
              <p className="font-xxl font-bold color-dark-black">
                {formatPrice(Number(transactionDetail?.transactionAmount))}
              </p>
            </>
          )}
        </div>
      </div>

      <div className="container">
        <p
          className={`font-sm font-light ${styles.title}`}
          style={{
            margin: '24px 0',
          }}
        >
          {t('paragraph.pr37')}
          <span className="color-orange"> {t('paragraph.pr38')}</span>
        </p>
        <p className={`color-dark-black font-bold font-lg ${styles.title}`}>
          {t('title.select-payment-method')}
        </p>
        <div className={styles.paymentSelector}>
          <PaymentMethodSelector
            listCard={listSaveCard}
            selectedMethodId={selectedMethodId}
            selectedCardId={selectedCardId}
            onAddNewCard={handleAddCard}
            onChangePaymentMethod={handleChangeMethodId}
            onChangeCard={handleChangeCardId}
          />
        </div>
      </div>
      <div className={styles.controller}>
        <SecondaryButton text={t('button.back')} variant="outline" onClick={goBack} />
        <SecondaryButton
          text={t('button.confirm')}
          variant="high-light-blue"
          onClick={onOpenConfirm}
        />
      </div>
      <ConfirmModal
        confirmTitle={t('title.confirm')}
        confirmContent={t('paragraph.sure-payment')}
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        onConfirm={createPTContract}
      />
      <BottomSpace />
    </div>
  );
}
